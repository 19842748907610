// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-about-us-js": () => import("./../../../src/pages/about-us/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-about-us-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-concert-hall-concert-hall-js": () => import("./../../../src/pages/concert-hall/ConcertHall.js" /* webpackChunkName: "component---src-pages-concert-hall-concert-hall-js" */),
  "component---src-pages-concert-hall-index-js": () => import("./../../../src/pages/concert-hall/index.js" /* webpackChunkName: "component---src-pages-concert-hall-index-js" */),
  "component---src-pages-concerts-performances-concert-performances-js": () => import("./../../../src/pages/concerts-performances/ConcertPerformances.js" /* webpackChunkName: "component---src-pages-concerts-performances-concert-performances-js" */),
  "component---src-pages-concerts-performances-index-js": () => import("./../../../src/pages/concerts-performances/index.js" /* webpackChunkName: "component---src-pages-concerts-performances-index-js" */),
  "component---src-pages-contact-us-contact-us-js": () => import("./../../../src/pages/contact-us/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-contact-us-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-home-home-about-js": () => import("./../../../src/pages/home/HomeAbout.js" /* webpackChunkName: "component---src-pages-home-home-about-js" */),
  "component---src-pages-home-home-banner-js": () => import("./../../../src/pages/home/HomeBanner.js" /* webpackChunkName: "component---src-pages-home-home-banner-js" */),
  "component---src-pages-home-home-concert-hall-js": () => import("./../../../src/pages/home/HomeConcertHall.js" /* webpackChunkName: "component---src-pages-home-home-concert-hall-js" */),
  "component---src-pages-home-home-cta-js": () => import("./../../../src/pages/home/HomeCta.js" /* webpackChunkName: "component---src-pages-home-home-cta-js" */),
  "component---src-pages-home-home-ig-feed-js": () => import("./../../../src/pages/home/HomeIgFeed.js" /* webpackChunkName: "component---src-pages-home-home-ig-feed-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-school-index-js": () => import("./../../../src/pages/music-school/index.js" /* webpackChunkName: "component---src-pages-music-school-index-js" */),
  "component---src-pages-music-school-music-school-class-js": () => import("./../../../src/pages/music-school/MusicSchoolClass.js" /* webpackChunkName: "component---src-pages-music-school-music-school-class-js" */),
  "component---src-pages-music-teachers-index-js": () => import("./../../../src/pages/music-teachers/index.js" /* webpackChunkName: "component---src-pages-music-teachers-index-js" */),
  "component---src-pages-music-teachers-music-teachers-js": () => import("./../../../src/pages/music-teachers/MusicTeachers.js" /* webpackChunkName: "component---src-pages-music-teachers-music-teachers-js" */),
  "component---src-pages-online-lesson-index-js": () => import("./../../../src/pages/online-lesson/index.js" /* webpackChunkName: "component---src-pages-online-lesson-index-js" */),
  "component---src-pages-online-lesson-music-world-wide-index-js": () => import("./../../../src/pages/online-lesson/music-world-wide/index.js" /* webpackChunkName: "component---src-pages-online-lesson-music-world-wide-index-js" */),
  "component---src-pages-online-program-fast-track-index-js": () => import("./../../../src/pages/online-program/fast-track/index.js" /* webpackChunkName: "component---src-pages-online-program-fast-track-index-js" */),
  "component---src-pages-online-program-index-js": () => import("./../../../src/pages/online-program/index.js" /* webpackChunkName: "component---src-pages-online-program-index-js" */),
  "component---src-pages-online-program-music-world-wide-index-js": () => import("./../../../src/pages/online-program/music-world-wide/index.js" /* webpackChunkName: "component---src-pages-online-program-music-world-wide-index-js" */),
  "component---src-pages-online-program-online-program-js": () => import("./../../../src/pages/online-program/OnlineProgram.js" /* webpackChunkName: "component---src-pages-online-program-online-program-js" */),
  "component---src-pages-online-program-parts-section-faq-js": () => import("./../../../src/pages/online-program/parts/SectionFAQ.js" /* webpackChunkName: "component---src-pages-online-program-parts-section-faq-js" */),
  "component---src-pages-online-program-parts-section-testimonial-js": () => import("./../../../src/pages/online-program/parts/SectionTestimonial.js" /* webpackChunkName: "component---src-pages-online-program-parts-section-testimonial-js" */)
}

